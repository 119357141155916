import { Toast } from "vant";

export const mixins = {
  data() {
    return {
      urlList: [
        {
          url: "https://d37c4bvlzt4hg5.cloudfront.net/?dc=HLBDY",
          icon: require("./../assets/png/app-lld.webp"),
          name: "lld",
          time: 0,
        },
        {
          url: "https://d1i35ed6tve17z.cloudfront.net?dc=HLBDY",
          icon: require("./../assets/png/app-nut.webp"),
          name: "nut",
          time: 0,
        },
        {
          url: "https://d1r5jv7gjtlvw9.cloudfront.net?dc=HLBDY",
          icon: require("./../assets/png/app-hjll.webp"),
          name: "hjll",
          time: 0,
        },
        {
          url: "https://haose.07iz2h.com?dc=HLBDY",
          icon: require("./../assets/png/app-haose.webp"),
          name: "haose",
          time: 0,
        },
        {
          url: "https://dftkpjedp1rk7.cloudfront.net/?dc=HLBDY",
          icon: require("./../assets/png/app-anwang.webp"),
          name: "anwang",
          time: 0,
        },
        {
          url: "https://m3l1i.com/?dc=HLBDY",
          icon: require("./../assets/png/app-lieqi.webp"),
          name: "91lq",
          time: 0,
        },
        {
          url: "https://yjdm123.1ih5l.com/yjdm/user.html?dc=HLBDY",
          icon: require("./../assets/png/app-yjmh.webp"),
          name: "yjmh",
          time: 0,
        },
        {
          url: "https://d2e4mngckb3pse.cloudfront.net?dc=HLBDY",
          icon: require("./../assets/png/app-qysq.webp"),
          name: "qysq",
          time: 0,
        },
        {
          url: "https://hls123.a5vs3.com/hls/user.html?dc=HLBDY",
          icon: require("./../assets/png/app-hls.webp"),
          name: "hls",
          time: 0,
        },
        {
          url: "https://jklsidx.9yl1m.vip?dc=HLBDY",
          icon: require("./../assets/png/app-smtt.webp"),
          name: "smtt",
          time: 0,
        },
        {
          url: "https://d1cn70esn54o9t.cloudfront.net?dc=HLBDY",
          icon: require("./../assets/png/app-tiktok.webp"),
          name: "tiktok",
          time: 0,
        },
        {
          url: "https://asfdsafd.dlu83.com/51ll/user.html?dc=HLBDY",
          icon: require("./../assets/png/app-51ll.webp"),
          name: "51ll",
          time: 0,
        },
        {
          url: "https://51bs123.x3tj5.com/51bs/user.html?dc=HLBDY",
          icon: require("./../assets/png/app-51bense.webp"),
          name: "51Bense",
          time: 0,
        },
        {
          url: "https://d18hzhbodi9dcx.cloudfront.net?dc=HLBDY",
          icon: require("./../assets/png/app-awjd.webp"),
          name: "awjd",
          time: 0,
        },
        {
          url: "https://d18hsc9gskt87h.cloudfront.net?dc=HLBDY",
          icon: require("./../assets/png/app-paofu.webp"),
          name: "paofu",
          time: 0,
        },
        {
          url: "https://naisi123.tu9h6.com/nais/user.html?dc=HLBDY",
          icon: require("./../assets/png/app-ns.webp"),
          name: "ns",
          time: 0,
        },
      ], // 域名数组
      ping: 1,
      timer: null,
      showImg: true,
      addressUrl: "cao.gg",
    };
  },
  mounted() {
    this.addressUrl = window.location.host;
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init() {
      this.timer = setInterval(() => {
        this.ping++;
      }, 100);
    },
    autoTest(item, index) {
      if (this.ping <= 10) {
        item.time = this.ping * 10;
      } else {
        item.time = this.ping * 100;
      }
      if (index == this.urlList.length - 1) {
        clearInterval(this.timer);
      }
    },
    jumpUrl(url) {
      window.open(url);
    },
    reset() {
      this.urlList = this.urlList.map((item) => {
        item.time = 0;
        return item;
      });
      this.showImg = false;
      this.$nextTick(() => {
        this.showImg = true;
      });
      this.ping = 1;
      this.init();
    },
    doCopy() {
      let req = this.addressUrl;
      this.$copyText(req).then(
        () => {
          Toast("复制成功");
        },
        () => {
          Toast("复制失败");
        }
      );
    },
  },
};
